import classNames from 'classnames/bind';
import styles from './DefaultLayout.module.scss';

import Navbar from '../components/Navbar';
import Header from '../components/Header';
import HeaderTwo from '../components/Header/HeaderTwo';

const cx = classNames.bind(styles);

function DefaultLayoutThree({ children }) {
    document.title = children.props.title;
    return (
        <div className={cx('wrapper')}>
            <div className={cx('container-fluid')}>
                <div className="min-h-[100vh] bg-[#ea943f] w-full flex">
                    <HeaderTwo title={children.props.title} />
                    <div className={cx('content')}>{children}</div>
                    <Navbar link={children.props.link} title={children.props.title} />
                </div>
            </div>
        </div>
    );
}

export default DefaultLayoutThree;
