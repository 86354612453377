import React from "react";
import { twMerge } from "tailwind-merge";
import { CgSpinner } from "react-icons/cg";

const Button = ({
  rootClassName,
  title = "Loading",
  isLoading,
  disabled,
  htmlType = "button",
  ...props
}) => {
  return (
    <button
      {...props}
      type={htmlType}
      className={twMerge(
        `text-[#f0f0f0] bg-secondary text-lg font-medium h-[46px] rounded-md w-full flex justify-center items-center gap-2 disabled:bg-[#F2F2F2] disabled:text-[#989898] disabled:cursor-not-allowed`,
        rootClassName
      )}
      disabled={disabled}
      key={Math.random()}
    >
      {isLoading && <CgSpinner className="animate-spin" size={22} />}

      <span className="">{title}</span>
    </button>
  );
};

export default Button;
