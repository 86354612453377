import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';

import { MdLocalOffer } from 'react-icons/md';
import SETTINGS from '../../setting.json';
import Select from 'react-select';
import { list_money_recharge } from '../../utils/consts';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLinkCSKH } from '../../utils/function';

const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

function Recharge({ title }) {
    document.title = title;
    const link_cskh = getLinkCSKH();
    const [option, setOption] = useState([]);
    const [bankSelected, setBankSelected] = useState({});
    const [select, setSelect] = useState('bank');
    const [money, setMoney] = useState(0);
    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            if (location.state.missingAmount) {
                setMoney(location.state.missingAmount);
            }
        }
    }, []);
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/bank/list`, {
                headers,
            })
            .then(function (response) {
                let data = response.data;
                const dataTransformer = data.data.map((item) => {
                    return { value: item.name_bank + '-' + item.stk, label: item.name_bank + '-' + item.stk, ...item };
                });
                setOption(dataTransformer);
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    function RechargeHandler() {
        if (!money || money < 100000) return toast.warn(`${t('content.recharge.minNap')}`, { theme: 'light' });

        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/recharge/add`,
                {
                    money,
                    select,
                    name_bank: bankSelected?.name_bank,
                    name_user: bankSelected?.name_user,
                    stk: bankSelected?.stk,
                },
                {
                    headers,
                },
            )
            .then(function (response) {
                let data = response.data;

                if (data.status === 1) {
                    toast.success(data.message, { theme: 'light' });
                    setTimeout(() => {
                        window.open(link_cskh, '_blank');
                    }, 2000);
                }
                if (data.status === 2) toast.warn(data.message, { theme: 'light' });
                // if (data.status) {
                //     setTimeout(() => {
                //         // window.location.href = `/recharge/${data.id_txn}`;
                //         window.open(
                //             '/support',
                //             '_blank', // <- This is what makes it open in a new window.
                //         );
                //     }, 1500);
                // } else {
                //     localStorage.removeItem('auth');
                //     window.location.href = '/account/login';
                // }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }

    useEffect(() => {
        if (option.length > 0) {
            setBankSelected(option[0]);
        }
    }, [option]);

    const handleSelectChange = (bank) => {
        setBankSelected(bank);
    };

    return (
        <div>
            <div
                className="recharge main-container bg-[#f0f3f7] overflow-x-hidden flex flex-col items-center justify-center pb-[70px]"
                style={{ borderRadius: '25px' }}
            >
                <div className="flex bg-[#d4edda] border-[#c3e6cb] text-[#155724] rounded-3xl mt-8 p-4 mx-12">
                    <div className="w-[40px] h-[40px] flex items-center justify-center bg-[white] rounded-full">
                        <MdLocalOffer size={24} color="green" />
                    </div>
                    <div className="ml-4 flex flex-col items-start justify-center text-2xl w-[80%]">
                        <span className="font-bold">{t('content.recharge.loiKhuyen')}:</span>
                        <span className="mt-2 leading-10">{t('content.recharge.vuiLong')}!</span>
                    </div>
                </div>
                <div className="flex flex-col mt-4">
                    <label className="mr-2 text-2xl">Chọn ngân hàng:</label>
                    <Select className="text-2xl" onChange={handleSelectChange} value={bankSelected} options={option} />
                </div>
                <div className="flex flex-col w-full px-4 mt-4 text-2xl">
                    <div className="flex items-center justify-between gap-4 mb-4">
                        <label className="whitespace-nowrap">Tên ngân hàng:</label>
                        <div className="relative w-full">
                            <input
                                disabled={true}
                                value={bankSelected?.name_bank || 'Chưa có'}
                                className="w-full pl-4 pr-[80px] py-2 text-right"
                            />
                            <span
                                onClick={() => {
                                    toast.success('Đã copy tên ngân hàng');
                                    navigator.clipboard.writeText(bankSelected?.name_bank);
                                }}
                                className="absolute px-4 py-1 font-semibold text-white -translate-y-1/2 bg-orange-400 border rounded cursor-pointer right-2 top-1/2 "
                            >
                                Copy
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-4 mb-4">
                        <label className="whitespace-nowrap">Số tài khoản:</label>
                        <div className="relative w-full">
                            <input
                                disabled={true}
                                value={bankSelected?.stk || 'Chưa có'}
                                className="w-full pl-4 pr-[80px] py-2 text-right"
                            />
                            <span
                                onClick={() => {
                                    toast.success('Đã copy số tài khoản');
                                    navigator.clipboard.writeText(bankSelected?.stk);
                                }}
                                className="absolute px-4 py-1 font-semibold text-white -translate-y-1/2 bg-orange-400 border rounded cursor-pointer right-2 top-1/2 "
                            >
                                Copy
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-4 mb-4">
                        <label className="whitespace-nowrap">Chủ tài khoản:</label>
                        <div className="relative w-full">
                            <input
                                disabled={true}
                                value={bankSelected?.name_user || 'Chưa có'}
                                className="w-full pl-4 pr-[80px] py-2 text-right"
                            />
                            <span
                                onClick={() => {
                                    toast.success('Đã copy Chủ tài khoản');
                                    navigator.clipboard.writeText(bankSelected?.name_user);
                                }}
                                className="absolute px-4 py-1 font-semibold text-white -translate-y-1/2 bg-orange-400 border rounded cursor-pointer right-2 top-1/2 "
                            >
                                Copy
                            </span>
                        </div>
                    </div>
                    {bankSelected?.qr_code && <img src={bankSelected?.qr_code} className="w-[170px] mx-auto" />}
                    {/* image này lấy từ vietqr */}
                </div>
                <div className="flex flex-row flex-wrap w-full px-4 mt-4">
                    {list_money_recharge.map((item, i) => {
                        return (
                            <span
                                key={i}
                                className={`min-w-[6rem] flex-1  m-2  text-center p-2 rounded-lg text-white ${
                                    money == item ? 'bg-[black] ' : 'bg-[#007BFF]  '
                                }  `}
                                onClick={() => setMoney(item)}
                            >
                                {formatter.format(item)}
                            </span>
                        );
                    })}
                </div>
                <div>
                    <div className="text-center my-[25px]">
                        {/* <p className="text-3xl text-[#005652]">{t('content.recharge.soTien')}:</p>
                        <div className="text-[#ffaa03] text-[32px] font-bold">{formatter.format(money) || '0'}</div> */}
                        <div className="form-group px-[15px] mt-[25px]">
                            <p className="text-left py-[5px] text-[14px] font-semibold">Vui lòng nhập số tiền nạp</p>
                            <div className="flex rounded-lg p-[10px]">
                                <input
                                    value={money}
                                    onChange={(e) => setMoney(e.target.value)}
                                    className="w-[100%] text-center p-4 text-[3rem] rounded-lg"
                                    type="number"
                                    placeholder={t('content.recharge.nhapSoTien')}
                                    spellCheck="false"
                                    maxLength="13"
                                    defaultValue={money || location?.state?.missingAmount}
                                />
                            </div>
                        </div>
                        <div className="w-[90%] m-auto">
                            <button onClick={() => RechargeHandler()} className="btn-submit">
                                Gửi đi
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Recharge;
