export function convertTime(timestamp) {
    // Convert the timestamp to a Date object
    const date = new Date(parseInt(timestamp));

    // Specify the options for formatting the date
    const options = {
        timeZone: 'Asia/Ho_Chi_Minh', // Vietnam timezone
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    // Format the date to a string representing the Vietnam time
    const vietnamTime = date.toLocaleString('vi-VN', options);

    return vietnamTime;
}

export const getLinkCSKH = () => {
    return localStorage.getItem('link_cskh');
};

export function getNumber(chuoi) {
    // Sử dụng biểu thức chính quy để lấy số từ chuỗi
    const soChi = chuoi.match(/\d+/);

    // Kiểm tra xem có số nào không
    if (soChi) {
        // Trả về số được lấy từ chuỗi
        return parseInt(soChi[0], 10);
    } else {
        // Trả về 0 hoặc giá trị mặc định tùy thuộc vào yêu cầu của bạn
        return 0;
    }
}

export function formatMoney(amount) {
    // Chuyển số tiền thành chuỗi và tách phần nguyên và phần thập phân
    let strAmount = amount.toString();
    let integerPart, decimalPart;
    if (strAmount.includes('.')) {
        [integerPart, decimalPart] = strAmount.split('.');
    } else {
        integerPart = strAmount;
        decimalPart = '00';
    }

    // Đảm bảo phần thập phân luôn có 2 chữ số
    decimalPart = decimalPart.padEnd(2, '0');

    // Định dạng phần nguyên với dấu phân cách hàng nghìn
    let formattedIntegerPart = parseFloat(integerPart).toLocaleString('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0,
    });

    // Nối lại phần nguyên và phần thập phân
    let formattedAmount = formattedIntegerPart + '.' + decimalPart;

    return formattedAmount;
}

// tạo array từ mảng ban đầu
export function createArrayWithDesiredLength(originalArray, desiredLength) {
    const repetitions = Math.ceil(desiredLength / originalArray.length);
    const newArray = [];
    for (let i = 0; i < repetitions; i++) {
        newArray.push(...originalArray);
    }
    return newArray.slice(0, desiredLength);
}
