import { useEffect, useState, useNavigate } from 'react';
import { useTranslation } from 'react-i18next';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import { convertTime } from '../../utils/function';
import { roses_user } from '../../utils/consts';
import Loading from 'react-loading';
import { useUserSocket } from '../../context/SocketProvider';

const axios = require('axios').default;
function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

export function SendMisson() {
    // const [isLoading, setIsLoading] = useState(false);
    const { onMessage } = useUserSocket();
    const [isMatching, setIsMatching] = useState(false);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isCallApiUser, setIsCallApiUser] = useState(false);
    const [order, setOrder] = useState();
    const [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0, roses: 0 }],
        moneyEarn: { moneyEarnPreDay: 0, moneyEarnToday: 0 },
        mission: {
            amount: 0,
            result: 0,
            amountToday: 0,
        },
    });
    const [disableBtn, setDisableBtn] = useState(false);

    // const navigate = useNavigate();
    const fetchUserData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            });

            setTimeout(() => {
                setIsLoading(false);
            }, 1000);

            const data = response.data;
            if (data.status === 'ok') {
                setUser(data);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(t('content.error'), { theme: 'light' });
        }
    };
    useEffect(() => {
        fetchUserData();
    }, [isCallApiUser]);
    useEffect(() => {
        onMessage('updateUser', (data) => {
            fetchUserData();
        });
    }, [onMessage]);
    useEffect(() => {
        setIsLoading(true);
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };

        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/new`,
                { level: '1' },
                {
                    headers,
                },
            )
            .then(async function (response) {
                setIsLoading(false);
                let data = response.data;
                console.log(data);
                if (data.status === 'success') {
                    if (data.result.type == 1) {
                        setOrder(data.result.mission?.[0]);

                        return;
                    }

                    if (data.result.type === 2) {
                        toast.warn(`${t('content.order.chuaCoSpMoi')}`, { theme: 'light' });
                        return;
                    }
                    if (data.result.type === 3) {
                        toast.warn(`${t('content.order.tkBiKhoa')}`, { theme: 'light' });
                        setTimeout(() => {
                            window.location.reload();
                            // navigate(`/order/mission/${id_mission}`);
                        }, 1000);
                        return;
                    }
                    if (data.result.type === 6) {
                        toast.success(`${t('content.order.donHangChuaHT')}`, { theme: 'light' });
                        await sleep(1000);
                        setTimeout(() => {
                            // navigate(`/order/confirm/${data.data.mission.id_mission}`);
                        }, 1000);
                        return;
                    }
                    if (data.result.type === 1000) {
                        toast.info(`${t('content.order.daHTDuDon')}`, { theme: 'light' });
                        setTimeout(() => {
                            window.location.reload();
                            // navigate(`/order/mission/${id_mission}`);
                        }, 1000);
                        return;
                    }
                }
            })
            .catch(function (error) {
                console.log(`error : `, error);
                setIsLoading(false);
                if (error.response?.status === 403)
                    return toast.warn(t('content.order.timeLayDonHang'), { theme: 'light' });
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    function confirmMission(orderCode) {
        // setIsMatching(true);
        setIsLoading(true);
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/confirm/id`,
                { orderCode: orderCode },
                {
                    headers,
                },
            )
            .then(async function (response) {
                setDisableBtn(true);
                setIsLoading(false);
                let data = response.data;
                if (data.status === 'success') {
                    if (data.result.type === 1000) {
                        // setIsMatching(false);
                        setDisableBtn(false);

                        return toast.warn(t('content.order.htDDH'), {
                            theme: 'light',
                        });
                    }

                    if (data.result.type === 2) {
                        // setIsMatching(false);
                        setDisableBtn(false);
                        return toast.warn(`${t('content.confirmOrder.nvKTonTai')}`, { theme: 'light' });
                    }

                    if (data.result.type === 3) {
                        // setIsMatching(false);
                        setDisableBtn(false);
                        return toast.warn(`${t('content.confirmOrder.daLamNv')}`, { theme: 'light' });
                    }

                    if (data.result.type === 4) {
                        // setIsMatching(false);
                        setDisableBtn(false);
                        return toast.warn(`${t('content.confirmOrder.capBacKDu')}`, { theme: 'light' });
                    }
                    if (data.result.type === 5) {
                        // setIsMatching(false);
                        setDisableBtn(false);
                        return toast.warn(`${t('content.confirmOrder.conNvChuaHT')}`, { theme: 'light' });
                    }

                    if (data.result.type === 0) {
                        // setIsMatching(false);
                        setDisableBtn(false);
                        setIsCallApiUser(!isCallApiUser);
                        return toast.warn(`${t('content.confirmOrder.soDuKDu')}`, { theme: 'light' });
                    }

                    if (data.result.type == 1) {
                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.donHangDangGui')}`);
                        // }, 0);

                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.doiHeThong')}`);
                        // }, 1000);

                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.doiNguoiBan')}`);
                        // }, 2000);

                        // setTimeout(() => {
                        //     setIsMatching(false);
                        // }, 2500);
                        setTimeout(() => {
                            toast.success('Gửi đơn hàng thành công');
                        }, 500);

                        setTimeout(() => {
                            setDisableBtn(false);
                            window.location.assign('order/mission');
                        }, 2000);
                    }
                }
            })
            .catch(function (error) {
                setIsLoading(false);
                if (error.response.status === 403)
                    return toast.warn(t('content.order.timeLayDonHang'), { theme: 'light' });
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }

    // console.log(user);
    return (
        <div className="bg-[#f0f3f7] rounded-3xl p-4">
            <div className="flex flex-col">
                {/* <div className="fs-3 font-medium">
                    <span className="mr-2">Thời gian nhận:</span>
                    {isLoading ? (
                        <Loading
                            type="spinningBubbles"
                            color="#e46937"
                            height={'20px'}
                            width={'20px'}
                            className="mb-3"
                        />
                    ) : (
                        <span>{convertTime(order?.time)}</span>
                    )}
                </div> */}
                <div className="fs-3 font-medium">
                    <span className="mr-2">Mã đơn hàng:</span>
                    {isLoading ? (
                        <Loading
                            type="spinningBubbles"
                            color="#e46937"
                            height={'20px'}
                            width={'20px'}
                            className="mb-3"
                        />
                    ) : (
                        <span className="text-[green]">{order?.orderCode}</span>
                    )}
                </div>
            </div>
            <img src={order?.image} className="w-[80%] h-auto m-auto my-4" />
            <div className="">
                <div className="goods-content flex flex-col items-start justify-center">
                    <div className="w-[100%] flex items-center mt-2">
                        <div className="goods_name  font-bold text-2xl">{order?.name_mission}</div>
                    </div>
                    <div className="w-[100%] flex flex-col">
                        <div className="flex items-center justify-between mt-2">
                            <span className="font-bold text-2xl">{t('content.order.soTien')}</span>
                            {isLoading ? (
                                <Loading
                                    type="spinningBubbles"
                                    color="#e46937"
                                    height={'20px'}
                                    width={'20px'}
                                    className="mb-3"
                                />
                            ) : (
                                <span className=" font-bold text-2xl">
                                    {formatter?.format((order?.price || 0)?.toFixed(2))}
                                </span>
                            )}
                        </div>
                        <div className="flex items-center justify-between mt-2">
                            {isLoading ? (
                                <Loading
                                    type="spinningBubbles"
                                    color="#e46937"
                                    height={'20px'}
                                    width={'20px'}
                                    className="mb-3"
                                />
                            ) : (
                                <span className="font-bold text-2xl">{`Hoa hồng `}</span>
                            )}
                            {isLoading ? (
                                <Loading
                                    type="spinningBubbles"
                                    color="#e46937"
                                    height={'20px'}
                                    width={'20px'}
                                    className="mb-3"
                                />
                            ) : (
                                <span className=" font-bold text-2xl">
                                    {formatter.format(order?.receive?.toFixed(2))}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <button
                className="btn-submit"
                disabled={disableBtn}
                onClick={() => {
                    confirmMission(order?.orderCode);
                }}
            >
                Gửi đơn hàng
            </button>
        </div>
    );
}
