import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Chart from '../../components/Chart/Chart';
import { MdLocalAtm, MdPayments, MdAccountBalanceWallet, MdSwapHoriz } from 'react-icons/md';
import ProgressBar from '@ramonak/react-progress-bar';
import SETTINGS from '../../setting.json';
import Loading from 'react-loading';
import WithDrawalButton from './_component/WithDrawalButton';
import { useUserSocket } from '../../context/SocketProvider';

const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

function Wallet({ title }) {
    document.title = title;
    const { onMessage } = useUserSocket();
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0, roses: 0 }],
        moneyEarn: { moneyEarnPreDay: 0, moneyEarnToday: 0 },
        mission: {
            amount: 0,
            result: 0,
            amountToday: 0,
        },
    });

    const { t } = useTranslation();
    const fetchUserData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            });

            setTimeout(() => {
                setIsLoading(false);
            }, 1000);

            const data = response.data;
            if (data.status === 'ok') {
                setUser(data);
            }
        } catch (error) {
            toast.error(t('content.error'), { theme: 'light' });
            setIsLoading(false);
        }
    };
    useEffect(() => {
        // checkToken();

        fetchUserData();
    }, []);
    useEffect(() => {
        onMessage('updateUser', (data) => {
            fetchUserData();
        });
    }, [onMessage]);
    return (
        <div>
            <div className="flex flex-col items-center justify-center text-white mt-16">
                <span className="" style={{ fontSize: '3rem', fontWeight: '500' }}>
                    {isLoading ? (
                        <Loading type="spinningBubbles" color="white" height={'20px'} width={'20px'} className="mb-3" />
                    ) : (
                        formatter.format(user?.data[0].money)
                    )}
                </span>
                <span className="text-2xl mt-3">{t('content.wallet.tongTS')}</span>
            </div>

            <Chart />

            <div
                className="main-container bg-[#f0f3f7] overflow-x-hidden flex flex-col items-center justify-center pb-[70px]"
                style={{ borderRadius: '25px' }}
            >
                <div className="flex items-center justify-between w-[100%]">
                    <div className="px-4 py-8 font-medium" style={{ fontSize: '16px' }}>
                        {t('content.wallet.ptTN')}
                    </div>
                    <div style={{ color: 'transparent' }}>1</div>
                </div>

                <div className="mt-4 tours-item flex flex-col items-center justify-start w-[92%] mx-2 rounded-2xl mb-4 p-4 bg-white">
                    <div className="flex items-center justify-between w-[100%]">
                        <div>
                            <span className="font-medium" style={{ fontSize: '20px' }}>
                                {isLoading ? (
                                    <Loading
                                        type="spinningBubbles"
                                        color="black"
                                        height={'20px'}
                                        width={'20px'}
                                        className="mb-3"
                                    />
                                ) : (
                                    formatter.format(user?.moneyEarn.moneyEarnToday)
                                )}
                            </span>
                            <div className="fs-3 text-[#b5b5b5]">{t('content.wallet.tnhh')}</div>
                        </div>
                        <div className="w-[40px] h-[40px] flex items-center justify-center bg-[#d0cdf4] rounded-full">
                            <MdLocalAtm size={24} color="#ea943f" />
                        </div>
                    </div>

                    <ProgressBar
                        completed={`${user?.mission?.amountToday}`}
                        maxCompleted={`${user.data[0].amount}`}
                        height="5px"
                        className="mt-8 w-[100%]"
                        customLabel=""
                        isLabelVisible={false}
                        bgColor="#d0cdf4"
                    ></ProgressBar>
                </div>

                <div className="mt-12 tours-item flex flex-col items-center justify-center w-[92%] mx-2 rounded-2xl mb-4 p-4 bg-white">
                    <div
                        className="flex items-center justify-between w-100 pb-4"
                        style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}
                    >
                        <span className="fs-3 font-medium">{t('content.wallet.ttctq')}</span>
                        <div style={{ color: 'transparent' }}>1</div>
                    </div>

                    <div className="flex flex-col items-start justify-center w-[100%]">
                        <Link to="/recharge" className="flex items-center justify-center my-4">
                            <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                <MdPayments size={24} color="#ea943f" />
                            </div>
                            <div className="flex flex-col ml-4">
                                <span className="fs-3">{t('content.wallet.napTien')}</span>
                                <span className="fs-4 text-[#6c757d]">{t('content.wallet.nlsd')}</span>
                            </div>
                        </Link>
                        {/* to="/withdraw" */}
                        <WithDrawalButton>
                            <div className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                    <MdAccountBalanceWallet size={24} color="#ea943f" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.wallet.rutTien')}</span>
                                    <span className="fs-4 text-[#6c757d]">{t('content.wallet.rtvtknh')}</span>
                                </div>
                            </div>
                        </WithDrawalButton>
                        <Link to="/my/history-recharge-withdraw" className="flex items-center justify-center my-4">
                            <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                <MdSwapHoriz size={24} color="#ea943f" />
                            </div>
                            <div className="flex flex-col ml-4">
                                <span className="fs-3">Lịch sử nạp rút</span>
                                <span className="fs-4 text-[#6c757d]">Xem lịch sử nạp rút</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Wallet;
