import React from 'react';
import { twMerge } from 'tailwind-merge';

const LabelForInput = ({ labelClassName, label }) => {
    return (
        <label className={twMerge('border-l-4 border-primary px-2 mb-2 text-lg text-white hidden', labelClassName)}>
            {label}
        </label>
    );
};

export default LabelForInput;
