import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SETTINGS from '../../setting.json';
import classNames from 'classnames/bind';
import styles from './My.module.scss';
import { useTranslation } from 'react-i18next';
import ErrMsg from '../../components/ui/ErrMsg';
import Button from '../../components/ui/Button';
import FormInput from '../../components/ui/Input/FormInput';
import { Controller, useForm } from 'react-hook-form';

const axios = require('axios').default;

const cx = classNames.bind(styles);
const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
function EditPassword({ title }) {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        checkToken();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const KEY_ACCEPT = {
        passwordOld: '',
        newPassword: '',
        repassword: '',
    };
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: KEY_ACCEPT,
    });
    const onSubmit = async (values) => {
        setIsLoading(true);
        const { passwordOld, newPassword } = values;
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .put(
                `${SETTINGS.BASE_URL}/api/webapi/change_password`,
                { passwordOld, newPassword },
                {
                    headers,
                },
            )
            .then(function (response) {
                let data = response.data;
                if (data.data === 1) {
                    toast.success(`${t('content.editPassword.doiMkThanhCong')}`, { theme: 'light' });
                    setTimeout(() => {
                        window.location.href = '/my';
                    }, 1500);
                }
                if (data.data === 2) toast.error(`${t('content.editPassword.saiMk')}`, { theme: 'light' });
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    document.title = title;
    return (
        <div className="px-[15px] py-[20px] h-[400px] bg-[white] rounded-3xl mt-24">
            <div className={cx('edit-password', { 'edit-password-style': true })}>
                <div className="form-edit-password p-[20px]">
                    <div className="text-3xl font-medium">{t('content.editPassword.doiMk')}</div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid gap-y-6">
                            <div className="relative">
                                <div className={cx('form-group', { '': true })}>
                                    <Controller
                                        control={control}
                                        name="passwordOld"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Không thể trống',
                                            },
                                        }}
                                        render={({ field }) => {
                                            return (
                                                <FormInput
                                                    type="password"
                                                    id="passwordOld"
                                                    placeholder={t('content.editPassword.mkHienTai')}
                                                    mainClassName="px-0"
                                                    rootClassName=""
                                                    inputClassName="text-[16px] outline-none rounded-[5px] px-[5px] py-[10px] pl-[12px] bg-white text-black border border-gray-300 h-auto focus:!border-[#e46937] !pr-14"
                                                    {...field}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <ErrMsg errMsg={errors?.passwordOld?.message} rootClassName="text-red-500 -mb-7" />
                            </div>
                            <div className="relative">
                                <div className={cx('form-group', { '': true })}>
                                    <Controller
                                        control={control}
                                        name="newPassword"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Không thể trống',
                                            },
                                            minLength: {
                                                value: 6,
                                                message: 'Tối thiểu 6 kí tự',
                                            },
                                            maxLength: {
                                                value: 30,
                                                message: 'Tối đa 30 kí tự',
                                            },
                                            pattern: {
                                                value: /^(?=.*[a-zA-Z]).{6,30}$/,
                                                message: 'Phải có ít nhất 1 kí tự chữ',
                                            },
                                        }}
                                        render={({ field }) => {
                                            return (
                                                <FormInput
                                                    type="password"
                                                    id="newPassword"
                                                    placeholder={t('content.editPassword.mkMoi')}
                                                    mainClassName="px-0"
                                                    rootClassName=""
                                                    inputClassName="text-[16px] outline-none rounded-[5px] px-[5px] py-[10px] pl-[12px] bg-white text-black border border-gray-300 h-auto focus:!border-[#e46937] !pr-14"
                                                    {...field}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <ErrMsg errMsg={errors?.newPassword?.message} rootClassName="text-red-500 -mb-7" />
                            </div>
                            <div className="relative">
                                <div className={cx('form-group', { '': true })}>
                                    <Controller
                                        control={control}
                                        name="repassword"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Không thể trống',
                                            },
                                            validate: (value) =>
                                                value === getValues('newPassword') || 'Mật khẩu không khớp',
                                        }}
                                        render={({ field }) => {
                                            return (
                                                <FormInput
                                                    type="password"
                                                    id="repassword"
                                                    placeholder={t('content.editPassword.xacNhanMkMoi')}
                                                    mainClassName="px-0"
                                                    rootClassName=""
                                                    inputClassName="text-[16px] outline-none rounded-[5px] px-[5px] py-[10px] pl-[12px] bg-white text-black border border-gray-300 h-auto focus:!border-[#e46937] !pr-14"
                                                    {...field}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <ErrMsg errMsg={errors?.repassword?.message} rootClassName="text-red-500 -mb-7" />
                            </div>
                        </div>
                        <Button
                            title={t('content.editPwTransaction.luuLai')}
                            rootClassName="bg-[#00b4ff] w-full rounded-[3rem] bg-[#00b4ff] text-white p-[0.7rem] mt-[1rem] text-[1.7rem] h-auto  mt-5"
                            htmlType="submit"
                            isLoading={isLoading}
                            disabled={isLoading}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditPassword;
