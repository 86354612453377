import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './History.css';
import { useNavigate } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';

const axios = require('axios').default;

function formateT(params) {
    let result = params < 10 ? '0' + params : params;
    return result;
}

const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

function timerJoin2(params = '') {
    let date = '';
    if (params) {
        date = new Date(Number(params));
    } else {
        date = new Date();
    }
    let years = formateT(date.getFullYear());
    let months = formateT(date.getMonth() + 1);
    let days = formateT(date.getDate());

    let hours = formateT(date.getHours());
    let minutes = formateT(date.getMinutes());
    let seconds = formateT(date.getSeconds());
    return years + '-' + months + '-' + days + ' ' + hours + ':' + minutes + ':' + seconds;
}

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function History({ title }) {
    document.title = title;
    let [type_mission, setType] = useState('all');
    let [mission, setMission] = useState([]);
    const [isMatching, setIsMatching] = useState(false);
    const [contentMatching, setContentMatching] = useState('');
    const [activeTab, setActiveTab] = useState('1');
    const [activeTabPane, setActiveTabPane] = useState('1');

    const { t } = useTranslation();

    useEffect(() => {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/mission/history`, {
                params: {
                    type: type_mission,
                },
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'success') {
                    setMission(data.result);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, [type_mission]);

    useEffect(() => {
        checkToken();
    }, []);
    let navigate = useNavigate();

    function confirmMission(orderCode) {
        setIsMatching(true);

        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/confirm/id`,
                { orderCode: orderCode },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;
                if (data.status === 'success') {
                    if (data.result.type === 1000) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.daHoanThanh')}`, { theme: 'light' });
                    }

                    if (data.result.type === 2) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.nvKTonTai')}`, { theme: 'light' });
                    }

                    if (data.result.type === 3) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.daLamNv')}`, { theme: 'light' });
                    }

                    if (data.result.type === 4) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.capBacKDu')}`, { theme: 'light' });
                    }
                    if (data.result.type === 5) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.conNvChuaHT')}`, { theme: 'light' });
                    }

                    if (data.result.type === 0) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.soDuKDu')}`, { theme: 'light' });
                    }

                    if (data.result.type === 1) {
                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.donHangDangGui')}`);
                        // }, 0);

                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.doiHeThong')}`);
                        // }, 1000);

                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.doiNguoiBan')}`);
                        // }, 2000);

                        setTimeout(() => {
                            setIsMatching(false);
                        }, 2500);

                        setTimeout(() => {
                            toast.success(`${t('content.confirmOrder.xacNhanDonHangThangCong')}`, { theme: 'light' });
                        }, 3000);

                        setTimeout(() => {
                            navigate(`/order/mission`);
                        }, 4000);
                    }
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }

    if (!Array.isArray(mission)) return false;

    const status = [
        {
            status: 0,
            name: 'Chờ gửi',
            color: 'bg-[#848995]',
        },
        {
            status: 1,
            name: 'Thành công',
            color: 'bg-[#1b67ec]',
        },
        {
            status: 2,
            name: 'Thất bại',
            color: 'bg-[#ff5236]',
        },
        {
            status: 3,
            name: 'Chờ duyệt',
            color: 'bg-[#fc9309]',
        },
    ];
    return (
        <div
            className="history-order main-container bg-[#ffffff] flex flex-col items-center justify-center mt-16 pb-[70px]"
            style={{ borderRadius: '25px' }}
        >
            {isMatching && (
                <div className="matching-animation">
                    <div className="overlay">
                        <div className="textConfirm">{contentMatching}</div>
                        <div className="loader loaderConfirm1"></div>
                        <div className="loader loaderConfirm2"></div>
                        <div className="loader loaderConfirm3"></div>
                    </div>
                </div>
            )}

            <Nav
                tabs
                className="history-body history-order__style tours-item w-[95%]"
                style={{ borderTop: 'none', fontSize: '12px' }}
            >
                <NavItem
                    className={activeTab === '1' ? 'active w-[20%]' : 'w-[20%]'}
                    onClick={() => {
                        setActiveTabPane('1');
                        setActiveTab('1');
                        setType('all');
                    }}
                >
                    {t('content.history.tatCa')}
                </NavItem>
                <NavItem
                    className={activeTab === '2' ? 'active w-[20%]' : 'w-[20%]'}
                    onClick={() => {
                        setActiveTabPane('2');
                        setActiveTab('2');
                        setType('pending');
                    }}
                >
                    Chờ Gửi
                </NavItem>
                <NavItem
                    className={activeTab === '3' ? 'active w-[20%]' : 'w-[20%]'}
                    onClick={() => {
                        setActiveTabPane('3');
                        setActiveTab('3');
                        setType('waiting');
                    }}
                >
                    {t('content.history.kiemDuyet')}
                </NavItem>
                <NavItem
                    className={activeTab === '4' ? 'active w-[20%]' : 'w-[20%]'}
                    onClick={() => {
                        setActiveTabPane('4');
                        setActiveTab('4');
                        setType('success');
                    }}
                >
                    {t('content.history.thanhCong')}
                </NavItem>
                <NavItem
                    className={activeTab === '5' ? 'active w-[20%]' : 'w-[20%]'}
                    onClick={() => {
                        setActiveTabPane('5');
                        setActiveTab('5');
                        setType('fail');
                    }}
                >
                    {t('content.history.thatBai')}
                </NavItem>
            </Nav>

            <div className="list-items w-[95%] mb-4">
                {mission.map((item, i) => {
                    return (
                        <div
                            className="w-[100%] mt-4 relative"
                            style={{ background: `url(${item.image})`, borderRadius: '25px', backgroundSize: 'cover' }}
                            key={i}
                        >
                            <div
                                className="background-black__style px-[18px] pb-[36px] pt-[20px] text-white"
                                style={{ borderRadius: '25px' }}
                            >
                                <div
                                    className={`status w-[auto] text-2xl font-bold px-4 py-2 absolute top-0 right-0  text-white ${
                                        status.find((s) => s.status === item.status).color
                                    }`}
                                    style={{ borderTopRightRadius: '25px', borderBottomLeftRadius: '25px' }}
                                >
                                    {status.find((s) => s.status === item.status).name}
                                </div>

                                <div
                                    className="header-confirm-order flex items-center justify-between pb-8"
                                    style={{ borderBottom: '1px solid white' }}
                                >
                                    <div className="content-confirm-order">
                                        <span className="text-2xl text-white">{timerJoin2(item.time)}</span>
                                        <br />
                                        <br />
                                        <span className="font-bold text-2xl text-white">{item.name_mission}</span>
                                        <br />
                                        <br />
                                        <span className="text-2xl text-white font-bold">
                                            {formatter.format(item.price.toFixed(2))}
                                        </span>
                                    </div>
                                </div>

                                <div className="flex flex-col">
                                    <div className="flex pt-4 items-center justify-between">
                                        <span className="text-2xl text-white font-bold">
                                            {t('content.history.soTien')}
                                        </span>
                                        <span className="text-2xl text-white font-bold">
                                            {formatter.format(item.price.toFixed(2))}
                                        </span>
                                    </div>

                                    <div className="flex pt-4 items-center justify-between">
                                        <span className="text-2xl text-white font-bold">Hoa hồng</span>
                                        <span className="text-2xl text-white font-bold">
                                            {formatter.format(item.receive.toFixed(2))}
                                        </span>
                                    </div>
                                </div>

                                {item.status === 0 && (
                                    <div
                                        className="mt-4 w-[100%] flex items-center justify-center bg-[#c62a1c] h-[4rem] rounded-lg text-white text-2xl font-semibold"
                                        onClick={() => confirmMission(item.orderCode)}
                                    >
                                        {t('content.history.gui')}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>

           
        </div>
    );
}

export default History;
