import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { DefaultLayout } from './components/Layout';
import { Fragment } from 'react';
import SETTINGS from './setting.json';
import axios from 'axios';
import { publicRoutes } from './routes';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSocketProvider } from './context/SocketProvider';

function App() {
    const { t } = useTranslation();
    useEffect(() => {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/settings`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                localStorage.setItem('link_cskh', response?.data?.data?.link_cskh);
            })
            .catch(function (error) {});
    }, []);
    return (
        <UserSocketProvider>
            <Router>
                <div className="App">
                    <Routes>
                        {publicRoutes.map((route, index) => {
                            let Layout = DefaultLayout;
                            if (route.layout) {
                                Layout = route.layout;
                            } else if (route.layout === null) {
                                Layout = Fragment;
                            }
                            const Page = route.component;
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={
                                        <Layout>
                                            <Page link={route.path} title={t(`content.routeTitle.${route.title}`)} />
                                        </Layout>
                                    }
                                    exact
                                />
                            );
                        })}
                    </Routes>
                </div>
            </Router>
        </UserSocketProvider>
    );
}

export default App;
