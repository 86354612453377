import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './Order.css';
import ProgressBar from '@ramonak/react-progress-bar';
// import { roses_user } from '../../utils/consts';
import image1 from '../../assets/images/order1.jpg';
import image2 from '../../assets/images/order2.jpg';
import image3 from '../../assets/images/order3.jpg';
import image4 from '../../assets/images/order4.jpg';
import image5 from '../../assets/images/order5.jpg';
import image6 from '../../assets/images/order6.jpg';
import image7 from '../../assets/images/order7.jpg';
import image8 from '../../assets/images/order8.jpg';
import image9 from '../../assets/images/order9.jpg';
import image10 from '../../assets/images/order10.webp';
import image11 from '../../assets/images/order11.jpg';
import image12 from '../../assets/images/order12.jpg';
import image13 from '../../assets/images/order13.jpg';
import image14 from '../../assets/images/order14.jpeg';
import image15 from '../../assets/images/order15.jpg';
import image16 from '../../assets/images/order16.jpg';
import image17 from '../../assets/images/order17.png';
import image18 from '../../assets/images/order18.webp';
import image19 from '../../assets/images/order19.jpg';
import image20 from '../../assets/images/order20.jpg';
// import image21 from '../../assets/images/order';
// import image22 from '../../assets/images/order';
// import image23 from '../../assets/images/order';
// import image24 from '../../assets/images/order';
// import image25 from '../../assets/images/order';
// import image26 from '../../assets/images/order';
// import image27 from '../../assets/images/order';
// import image28 from '../../assets/images/iorder;
// import image29 from '../../assets/images/order';
// import image30 from '../../assets/images/order';

import vinhhalong from '../../assets/images/vinhhalong.jpg';
import hoian from '../../assets/images/hoian.jpg';
import saigon from '../../assets/images/saigon.jpg';
import phuquoc from '../../assets/images/phuquoc.jpg';
import dalat from '../../assets/images/dalat.jpg';
import sapa from '../../assets/images/sapa.jpg';
import { createArrayWithDesiredLength } from '../../utils/function';
import vitri from '../../assets/images/vitri.png';

import audio from '../../assets/audio/hongbao.mp3';
import Loading from 'react-loading';
import { useUserSocket } from '../../context/SocketProvider';
const axios = require('axios').default;

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

const list20 = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
];
// const list120 = [...list_img, ...list_img, ...list_img, ...list_img];
// const list150 = [...list120, ...list_img];

function Order(args) {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisableButton, setIsDisableButton] = useState(true);
    const { t } = useTranslation();
    const [isModalOrder, setIsModalOrder] = useState(false);
    const [order, setOrder] = useState();
    const [isCallApiUser, setIsCallApiUser] = useState(false);
    const [star, setStar] = useState(5);
    const [isModalLucky, setIsModalLucky] = useState(false);
    const [missingAmount, setMissingAmount] = useState(0);
    // const [listImage, setListImage] = useState(list20);
    const [list100, setList100] = useState([...list20, ...list20, ...list20, ...list20]);
    const [roll, setRoll] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [roses, setRoses] = useState(0);
    const [listLevel, setListLevel] = useState([]);
    const { onMessage } = useUserSocket();
    // console.log(listImage);
    // useEffect(() => {
    //     setDisableBtn(true);
    //     const headers = {
    //         'x-access-token': localStorage.getItem('auth'),
    //         'Access-Control-Allow-Origin': '*',
    //     };
    //     axios
    //         .get(`${SETTINGS.BASE_URL}/api/webapi/list-image-mission`, {
    //             headers,
    //         })
    //         .then(async function (response) {
    //             setDisableBtn(false);
    //             let data = response.data;
    //             console.log(data);
    //             setListImage(data.data.listImageMissions);
    //             setList100(createArrayWithDesiredLength(data.data.listImageMissions, 100));
    //         })
    //         .catch(function (error) {
    //             setIsMatching(false);
    //             if (error.response?.status === 403)
    //                 return toast.warn(t('content.order.timeLayDonHang'), { theme: 'light' });
    //                 toast.error(`${t('content.error')}`, { theme: 'light' });
    //             return;
    //         });
    // }, []);
    useEffect(() => {
        onMessage('updateUser', (data) => {
            fetchUserInfo();
        });
    }, [onMessage]);
    useEffect(() => {
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/level/list`, {
                headers,
            })
            .then(async function (response) {
                setListLevel(response.data.data);
                // let data = response.data;
                // console.log(data);
            })
            .catch(function (error) {
                setIsMatching(false);
                if (error.response?.status === 403)
                    // return toast.warn(t('content.order.timeLayDonHang'), { theme: 'light' });
                    toast.error(`${t('content.error')}`, { theme: 'light' });
                return;
            });
    }, []);

    // console.log(user);
    // const img_box = [listImage, list150, list180];
    // console.log();
    // console.log(listImage);
    const descriptionHome = [
        {
            title: t('content.home.titleVHL'),
            image: vinhhalong,
            content: t('content.home.contentVHL'),
        },
        {
            title: t('content.home.titleHA'),
            image: hoian,
            content: t('content.home.contentHA'),
        },
        {
            title: t('content.home.titleSG'),
            image: saigon,
            content: t('content.home.contentSG'),
        },
        {
            title: t('content.home.titlePQ'),
            image: phuquoc,
            content: t('content.home.contentPQ'),
        },
        {
            title: t('content.home.titleDL'),
            image: dalat,
            content: t('content.home.contentDL'),
        },
        {
            title: t('content.home.titleSP'),
            image: sapa,
            content: t('content.home.contentSP'),
        },
    ];

    const toggleModalOrder = () => {
        setIsModalOrder(!isModalOrder);
        setTimeout(() => {
            window.location.assign('/sendMission');
        }, 1000);
    };
    const toggleModalLucky = () => setIsModalLucky(!isModalLucky);

    const currentTime = new Date().getHours();

    useEffect(() => {
        if (currentTime >= 9 && currentTime <= 24) {
            setIsDisableButton(false);
        }
    }, [currentTime]);

    const [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0, roses: 0 }],
        moneyEarn: { moneyEarnPreDay: 0, moneyEarnToday: 0 },
        mission: {
            amount: 0,
            result: 0,
            amountToday: 0,
        },
    });

    const navigate = useNavigate();

    const [isMatching, setIsMatching] = useState(false);

    const playAudio = () => {
        new Audio(audio).play();
    };

    const handSendMission = async () => {
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };

        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/new`,
                { level: '1' },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;

                if (data.status === 'success') {
                    setIsMatching(false);
                    if (data.result.type === 1) {
                        // console.log('hihi');
                        setOrder(data.result.mission?.[0]);
                        // setStar(data.data.mission.star);
                        setList100([
                            ...createArrayWithDesiredLength(list20, 60),
                            data.result.mission?.[0]?.image,
                            ...list20,
                        ]);
                        // console.log('hihi');
                        // console.log(data);
                        setRoll(true);
                        setDisableBtn(true);
                        setTimeout(() => {
                            toast.success(`${t('content.order.timThay1DonHang')}`, { theme: 'light' });
                        }, 3000);
                        setTimeout(() => {
                            setRoll(false);
                            // setIsModalOrder(true);
                            window.location.assign('/sendMission');
                            setDisableBtn(false);
                        }, 4000);
                        if (data.result.missing_amount) {
                            setIsModalLucky(true);
                            setMissingAmount(data.daresultta.missing_amount);
                        }
                        return;
                    }

                    // if (data.data.type === '1') {
                    //     toast.success(t('content.order.coDHCHT'), { theme: 'light' });
                    //     setIsModalOrder(true);
                    //     setOrder(data.data.mission);
                    //     setStar(data.data.mission.star);
                    //     setListImage([data.data.mission.image, ...list120]);

                    //     return;
                    // }

                    if (data.result.type === 2) {
                        toast.warn(`${t('content.order.chuaCoSpMoi')}`, { theme: 'light' });
                        return;
                    }
                    if (data.result.type === 3) {
                        toast.warn(`${t('content.order.tkBiKhoa')}`, { theme: 'light' });
                        setTimeout(() => {
                            window.location.reload();
                            // navigate(`/order/mission/${id_mission}`);
                        }, 1000);
                        return;
                    }
                    if (data.result.type === 6) {
                        toast.success(`${t('content.order.donHangChuaHT')}`, { theme: 'light' });
                        await sleep(1000);
                        setTimeout(() => {
                            navigate(`/order/confirm/${data.result.mission.id_mission}`);
                        }, 1000);
                        return;
                    }
                    if (data.result.type === 1000) {
                        toast.info(`${t('content.order.daHTDuDon')}`, { theme: 'light' });
                        setTimeout(() => {
                            window.location.reload();
                            // navigate(`/order/mission/${id_mission}`);
                        }, 1000);
                        return;
                    }
                }
            })
            .catch(function (error) {
                setIsMatching(false);
                if (error.response?.status === 403)
                    return toast.warn(t('content.order.timeLayDonHang'), { theme: 'light' });
                toast.error(`${t('content.error')}`, { theme: 'light' });
                console.log('typeerror', error);
            });
    };

    function confirmMission(id_mission) {
        setIsModalOrder(false);
        setIsMatching(true);

        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/confirm/id`,
                { id_mission: id_mission },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;
                console.log(data);

                if (data.status === 'ok') {
                    if (data.data.type === 1000) {
                        setIsMatching(false);
                        return toast.warn(t('content.order.htDDH'), {
                            theme: 'light',
                        });
                    }

                    if (data.data.type === 2) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.nvKTonTai')}`, { theme: 'light' });
                    }

                    if (data.data.type === 3) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.daLamNv')}`, { theme: 'light' });
                    }

                    if (data.data.type === 4) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.capBacKDu')}`, { theme: 'light' });
                    }
                    if (data.data.type === 5) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.conNvChuaHT')}`, { theme: 'light' });
                    }

                    if (data.data.type === 0) {
                        setIsMatching(false);
                        setIsCallApiUser(!isCallApiUser);
                        return toast.warn(`${t('content.confirmOrder.soDuKDu')}`, { theme: 'light' });
                    }

                    if (data.data.type === 1) {
                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.donHangDangGui')}`);
                        // }, 0);

                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.doiHeThong')}`);
                        // }, 1000);

                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.doiNguoiBan')}`);
                        // }, 2000);

                        setTimeout(() => {
                            setIsMatching(false);
                        }, 2500);

                        setTimeout(() => {
                            toast.success(`${t('content.confirmOrder.xacNhanDonHangThangCong')}`, { theme: 'light' });
                        }, 3000);

                        setTimeout(() => {
                            setIsCallApiUser(!isCallApiUser);
                        }, 4000);
                    }
                }
            })
            .catch(function (error) {
                if (error.response.status === 403)
                    return toast.warn(t('content.order.timeLayDonHang'), { theme: 'light' });
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }
    const fetchUserInfo = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            });

            setTimeout(() => {
                setIsLoading(false);
            }, 1000);

            if (response.data.status === 'ok') {
                setUser(response.data);
            }
        } catch (error) {
            toast.error(t('content.error'), { theme: 'light' });
        }
    };
    useEffect(() => {
        // checkToken();
        fetchUserInfo();
    }, [isCallApiUser]);
    // console.log(listLevel?.filter((item) => item.id_level == user?.data[0].roses_user)[0].roses);
    return (
        <div className="main-container">
            {isMatching && (
                <div className="matching-animation">
                    <div className="overlay">
                        <div className="text flex">{t('content.order.khopTuDong')}</div>
                        <div className="loader loader1"></div>
                        <div className="loader loader2"></div>
                        <div className="loader loader3"></div>
                    </div>
                </div>
            )}
            {/* 
            {isModalLucky && (
                <Modal isOpen={isModalLucky} toggle={toggleModalLucky} {...args} centered zIndex={2000}>
                    <div className="modal-home-style">
                        <ModalHeader>{t('content.order.xinCM')}</ModalHeader>
                        <ModalBody>
                            <div className="text-xl leading-10 py-4 px-2">
                                {t('content.order.chucMB')}:{' '}
                                <span className="font-bold" style={{ color: 'green' }}>
                                    {formatter.format(missingAmount)}
                                </span>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Link
                                to="/recharge"
                                className="mx-2 w-[100%] flex items-center justify-center bg-[#c62a1c] h-[4.8rem] rounded-lg text-white text-2xl font-semibold"
                                state={{ missingAmount: missingAmount }}
                            >
                                {t('content.order.napTienNgay')}
                            </Link>
                        </ModalFooter>
                    </div>
                </Modal>
            )} */}

            {isModalOrder && (
                <Modal isOpen={isModalOrder} toggle={toggleModalOrder} {...args} centered>
                    <div className="modal-home-style">
                        <ModalHeader
                            className="relative"
                            style={{
                                background: `url(${order?.image})`,
                                backgroundSize: 'cover',
                                width: '100%',
                                height: '37rem',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <div className="goods-wrap">
                                <div className="goods-content flex flex-col items-start justify-center">
                                    <div className="text-goods w-[100%] flex items-center ml-4 mt-2">
                                        <img src={vitri} alt="" width={32} height={32} />
                                        <div className="goods_name text-white font-bold text-2xl">
                                            {order?.name_mission}
                                        </div>
                                    </div>
                                    <div className="goods w-[100%] flex flex-col px-4">
                                        <div className="goods-cell flex items-center justify-between mt-2">
                                            <span className="text-white font-bold text-2xl">
                                                {t('content.order.soTien')}
                                            </span>
                                            <span className="text-white font-bold text-2xl">
                                                {formatter.format(order?.price.toFixed(2))}
                                            </span>
                                        </div>
                                        <div className="goods-cell flex items-center justify-between mt-2">
                                            <span className="text-white font-bold text-2xl">Hoa hồng</span>
                                            <span className="text-white font-bold text-2xl">
                                                {formatter.format(order?.receive.toFixed(2))}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter>
                            <div
                                className="mx-2 w-[100%] flex items-center justify-center bg-[#c62a1c] h-[4.8rem] rounded-lg text-white text-2xl font-semibold"
                                onClick={() => toggleModalOrder()}
                            >
                                Tới trang gửi đơn
                            </div>
                        </ModalFooter>
                    </div>
                </Modal>
            )}

            <div className="flex items-center justify-between mt-12 w-[100%]">
                <div className="ant-row ant-row-space-between w-[100%]">
                    <div className="ant-col">
                        <div className="sc-ikJyIC flex flex-col items-center justify-center">
                            <div className="sc-hiCibw iYoREV text-white fs-1 py-2 font-bold">
                                {isLoading ? (
                                    <Loading
                                        type="spinningBubbles"
                                        color="#e46937"
                                        height={'20px'}
                                        width={'20px'}
                                        className="mb-3"
                                    />
                                ) : (
                                    formatter.format(user?.data[0].money)
                                )}
                            </div>
                            <div className="sc-jJoQJp hzrkvO text-white text-2xl font-bold">
                                {t('content.order.soDuTK')}
                            </div>
                            {/* <div className="sc-hiCibw iYoREV text-[#e46937] fs-1 py-2 mt-12 font-bold">
                                {isLoading ? (
                                    <Loading
                                        type="spinningBubbles"
                                        color="#e46937"
                                        height={'20px'}
                                        width={'20px'}
                                        className="mb-3"
                                    />
                                ) : (
                                    user.data[0].amount
                                )}
                            </div>
                            <div className="sc-jJoQJp hzrkvO text-[#e46937] text-2xl font-bold">
                                {t('content.order.hanhTrinhHangNgay')}
                            </div> */}
                        </div>
                    </div>

                    <div className="ant-col">
                        <div className="sc-ikJyIC flex flex-col items-center justify-center">
                            <div className="sc-hiCibw iYoREV text-white fs-1 py-2 font-bold">
                                {isLoading ? (
                                    <Loading
                                        type="spinningBubbles"
                                        color="#e46937"
                                        height={'20px'}
                                        width={'20px'}
                                        className="mb-3"
                                    />
                                ) : (
                                    formatter.format(user?.moneyEarn.moneyEarnToday)
                                )}
                            </div>
                            <div className="sc-jJoQJp hzrkvO text-white text-2xl font-bold">
                                {t('content.order.hoaHongHomNay')}
                            </div>
                            {/* <div className="sc-hiCibw iYoREV text-[#e46937] fs-1 py-2 mt-12 font-bold">
                                {isLoading ? (
                                    <Loading
                                        type="spinningBubbles"
                                        color="#e46937"
                                        height={'20px'}
                                        width={'20px'}
                                        className="mb-3"
                                    />
                                ) : (
                                    user?.mission?.amountToday
                                )}
                            </div>
                            <div className="sc-jJoQJp hzrkvO text-[#e46937] text-2xl font-bold">
                                {t('content.order.soDonHoanThanh')}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#f0f3f7] rounded-3xl w-full p-4 my-4">
                <div className="bg-white w-full m-auto rounded-3xl p-4">
                    <div className="w-full h-[27rem] border-4 border-[blue] box-spin flex justify-between p-2 bg-[white] relative z-[5] overflow-hidden">
                        {[list100, list100, list100].map((item, i) => {
                            return (
                                <div key={i} className="w-[31%] h-auto overflow-hidden ">
                                    {item.map((img, index) => (
                                        <img
                                            key={index}
                                            src={img}
                                            className={`w-full h-[10rem] translate-y-[-2.5rem] ${roll ? 'roll' : ''}`}
                                            style={{
                                                transform: `translateY(${-2.5 - 10 * 59}rem)`,
                                                '--y': `${-2.5 - 10 * 59}rem`,
                                                '--time': `${2 + 0.5 * i}s`,
                                            }}
                                        />
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <button
                    className="btn-submit bg-[#3629b7!important] hover:bg-[#282552!important] "
                    disabled={disableBtn}
                    onClick={() => {
                        // if (isDisableButton) {
                        //     toast.warn(`${t('content.order.timeLayDonHang')}`, { theme: 'light' });
                        //     return;
                        // }

                        // if (user?.data[0].money < 100) {
                        //     toast.warn(`${t('content.order.soDuKhongDat')}`, { theme: 'light' });
                        //     return;
                        // }

                        setIsMatching(true);
                        handSendMission();
                    }}
                >
                    Kết hợp tự động
                </button>
            </div>
            <div className="bg-white rounded-3xl w-full p-4 mt-8">
                <div className="fs-3 font-medium ">
                    <span className="mr-2">Tỉ lệ hoa hồng:</span>
                    <span className="text-[green]">{`${
                        listLevel?.find((item) => item.id_level == user?.data[0].roses_user)?.roses
                    }%`}</span>
                </div>
                <div className="flex items-center justify-between w-[100%]">
                    <div className="fs-3 font-medium flex">
                        Đơn hàng hôm nay{' '}
                        <span className="fs-3 font-medium text-[green] ml-4">
                            {isLoading ? (
                                <Loading
                                    type="spinningBubbles"
                                    color="black"
                                    height={'20px'}
                                    width={'20px'}
                                    className="mb-3 ml-4"
                                />
                            ) : (
                                `${user?.mission?.amountToday}/${user.data[0].amount}`
                            )}
                        </span>
                    </div>
                    <div style={{ color: 'transparent' }}>1</div>
                </div>

                <ProgressBar
                    completed={`${user?.mission?.amountToday}`}
                    maxCompleted={`${user.data[0].amount}`}
                    height="5px"
                    className="mt-8 w-[100%]"
                    customLabel=""
                    isLabelVisible={false}
                    bgColor="green"
                ></ProgressBar>
            </div>
            {/* 
            <div
                className="main-container bg-[#ffffff] mt-4 overflow-x-hidden pb-[70px]"
                style={{ borderRadius: '25px' }}
            >
                <div className="flex items-center mb-2 animate-area-0 animated slideOutLeft infinite mt-4">
                    <img src={image1} alt="" className="dashboard-product-image" />
                    <img src={image2} alt="" className="dashboard-product-image" />
                    <img src={image3} alt="" className="dashboard-product-image" />
                    <img src={image4} alt="" className="dashboard-product-image" />
                    <img src={image5} alt="" className="dashboard-product-image" />
                    <img src={image6} alt="" className="dashboard-product-image" />
                    <img src={image7} alt="" className="dashboard-product-image" />
                    <img src={image8} alt="" className="dashboard-product-image" />
                    <img src={image9} alt="" className="dashboard-product-image" />
                    <img src={image10} alt="" className="dashboard-product-image" />
                    <img src={image11} alt="" className="dashboard-product-image" />
                    <img src={image12} alt="" className="dashboard-product-image" />
                    <img src={image13} alt="" className="dashboard-product-image" />
                    <img src={image14} alt="" className="dashboard-product-image" />
                    <img src={image15} alt="" className="dashboard-product-image" />
                    <img src={image16} alt="" className="dashboard-product-image" />
                    <img src={image17} alt="" className="dashboard-product-image" />
                    <img src={image18} alt="" className="dashboard-product-image" />
                    <img src={image19} alt="" className="dashboard-product-image" />
                    <img src={image20} alt="" className="dashboard-product-image" />
                    <img src={image21} alt="" className="dashboard-product-image" />
                    <img src={image22} alt="" className="dashboard-product-image" />
                    <img src={image23} alt="" className="dashboard-product-image" />
                    <img src={image24} alt="" className="dashboard-product-image" />
                    <img src={image25} alt="" className="dashboard-product-image" />
                    <img src={image26} alt="" className="dashboard-product-image" />
                    <img src={image27} alt="" className="dashboard-product-image" />
                    <img src={image28} alt="" className="dashboard-product-image" />
                    <img src={image29} alt="" className="dashboard-product-image" />
                    <img src={image30} alt="" className="dashboard-product-image" />
                </div>

                <div
                    className="flex items-center justify-center bg-[#e46937] text-white fs-2 font-semibold rounded-full mt-12 mb-4 mx-4 py-2"
                    onClick={() => {
                        if (isDisableButton) {
                            toast.warn(`${t('content.order.timeLayDonHang')}`, { theme: 'light' });
                            return;
                        }

                        if (user?.data[0].money < 100) {
                            toast.warn(`${t('content.order.soDuKhongDat')}`, { theme: 'light' });
                            return;
                        }

                        setIsMatching(true);
                        handSendMission();
                    }}
                >
                    {t('content.order.ketHopTD')}
                </div>

                <div className="flex items-center justify-center fs-2 font-semibold">Explore Tours</div>

                <div className="flex items-center justify-center description-order my-8 flex-wrap w-[100%]">
                    {descriptionHome.map((item, i) => {
                        return (
                            <div
                                key={i}
                                className="flex flex-col items-center justify-start w-[46%] mx-2 rounded-2xl border mb-4 tours-item"
                            >
                                <img src={item.image} alt="" className="w-[100%]" />
                                <div className="ml-4">
                                    <div className="title fs-4 font-bold">{item.title}</div>
                                    <div className="content-desc fs-5">{item.content}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div> */}
        </div>
    );
}

export default Order;
