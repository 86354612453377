import React, { useEffect, useState } from 'react';
import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';

function Service() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        toast.success('Vui lòng đợi chút, trong thời gian chúng tôi kết nối với CSKH Okela!', {
            theme: 'light',
        });

        setTimeout(() => {
            setIsLoading(false);
        }, 5000);
    }, []);

    function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
        console.log('LiveChatWidget.onNewEvent', event);
    }

    return (
        <>
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center">
                    <ReactLoading type="bubbles" color="#ff8a00" height={10} width={70} />
                </div>
            )}

            <LiveChatWidget license="16196067" visibility="maximized" />

          
        </>
    );
}

export default Service;
