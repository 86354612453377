// UserSocketProvider.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import SETTINGS from '../setting.json';
// Tạo context cho user socket
const UserSocketContext = createContext();

// Hook để sử dụng UserSocketContext
export const useUserSocket = () => useContext(UserSocketContext);

// Provider component dành cho UserSocket
export const UserSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    let accessToken = localStorage.getItem('auth');
    useEffect(() => {
        // Chỉ kết nối khi userId được cung cấp
        if (!accessToken) return;

        // Kết nối đến namespace /user với userId trong headers
        const newSocket = io(`${SETTINGS.BASE_URL}`, {
            extraHeaders: { 'user-id': accessToken },
        });

        // Xử lý khi kết nối thành công
        newSocket.on('connect', () => {
            console.log('Connected to /user namespace');
        });

        // Xử lý khi ngắt kết nối
        newSocket.on('disconnect', () => {
            console.log('Disconnected from /user namespace');
        });

        // Lưu trữ instance của socket
        setSocket(newSocket);

        // Dọn dẹp kết nối và listeners khi component bị hủy hoặc dependencies thay đổi
        return () => {
            newSocket.disconnect();
            setSocket(null); // Xóa tham chiếu socket khi cleanup
        };
    }, [accessToken]);

    // Gửi tin nhắn từ client đến server
    const sendMessage = (event, message) => {
        if (socket?.connected) {
            socket.emit(event, message);
        } else {
            console.warn('Socket is not connected');
        }
    };

    // Lắng nghe tin nhắn từ server với cleanup tự động
    const onMessage = (event, callback) => {
        if (socket) {
            socket.on(event, callback);
            // Dọn dẹp listener khi dependencies thay đổi
            return () => socket.off(event, callback);
        }
    };

    // Cung cấp context với các hàm gửi và lắng nghe tin nhắn
    return <UserSocketContext.Provider value={{ sendMessage, onMessage }}>{children}</UserSocketContext.Provider>;
};
