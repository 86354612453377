import React from 'react';
import { twMerge } from 'tailwind-merge';

const ErrMsg = ({ errMsg, rootClassName }) => {
    return (
        <p
            className={twMerge(
                'absolute -bottom-5 text-sm font-semibold pl-3',
                'text-[#FF0000] text-2xl -bottom-3',
                'text-[#000]',
                rootClassName,
            )}
        >
            {errMsg}
        </p>
    );
};

export default ErrMsg;
