import React from 'react';
import { checkToken } from '../../../../helpers';
import SETTINGS from '../../../../setting.json';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const axios = require('axios').default;
const WithDrawalButton = ({ children }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleClick = () => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/user/banking`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.data.recharge.length < 1 || !data.data.recharge[0].name_bank) {
                    return navigate('/my/banking', { state: { noteMsg: 'Vui lòng thêm thẻ ngân hàng để tiếp tục.' } });
                } else {
                    return navigate('/withdraw');
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error(`${t('content.error')}`, { theme: 'light' });
            })
            .finally(() => {});
    };
    return <div onClick={handleClick}>{children}</div>;
};

export default WithDrawalButton;
