import 'react-toastify/dist/ReactToastify.css';
import './My.css';

import { BsFillCreditCard2FrontFill, BsQrCodeScan } from 'react-icons/bs';
import {
    MdDiversity3,
    MdOutlineGavel,
    MdOutlineSupportAgent,
    MdPassword,
    MdPowerSettingsNew,
    MdQuiz,
    MdSecurity,
} from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';

import { AiOutlineGlobal } from 'react-icons/ai';
import { FaFileAlt } from 'react-icons/fa';
import { IoMdNotifications } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Loading from 'react-loading';
import { PiAddressBook } from 'react-icons/pi';
import ProgressBar from '@ramonak/react-progress-bar';
import SETTINGS from '../../setting.json';
import england from '../../assets/images/england.png';
import { getLinkCSKH } from '../../utils/function';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import vietnam from '../../assets/images/vietnam.png';
import vip1icon from '../../assets/images/vip1.png';
import vip2icon from '../../assets/images/vip2.png';
import vip3icon from '../../assets/images/vip3.png';
import vip4icon from '../../assets/images/vip4.png';
import vip5icon from '../../assets/images/vip5.png';
import vip6icon from '../../assets/images/vip6.png';
import vip7icon from '../../assets/images/angle_vip7.c46b7e40.png';
import vip8icon from '../../assets/images/VIP 8.png';
import { useUserSocket } from '../../context/SocketProvider';

const axios = require('axios').default;

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function My() {
    const { onMessage } = useUserSocket();
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0, roses: 0 }],
        moneyEarn: { moneyEarnPreDay: 0, moneyEarnToday: 0 },
        mission: {
            amount: 0,
            result: 0,
            amountToday: 0,
        },
    });

    const [nameUserBank, setNameUserBank] = useState();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'vi');

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const fetchUserBankInfo = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            });

            setTimeout(() => {
                setIsLoading(false);
            }, 1000);

            const data = response.data;
            if (data.status === 'ok') {
                setUser(data);
                if (data.userBank) {
                    setNameUserBank(data.userBank.name_u_bank);
                } else {
                    setNameUserBank(data.data[0].username);
                }
            }
        } catch (error) {
            toast.error(t('content.error'), { theme: 'light' });
            setIsLoading(false);
        }
    };
    useEffect(() => {
        checkToken();
        fetchUserBankInfo();
    }, []);
    useEffect(() => {
        onMessage('updateUser', (data) => {
            fetchUserBankInfo();
        });
    }, [onMessage]);

    const changeLanguage = (value) => {
        setLanguage(value);
        localStorage.setItem('language', value);
        i18n.changeLanguage(value);
    };

    useEffect(() => {
        if (language) i18n.changeLanguage(language);
    }, [language]);

    const link_cskh = getLinkCSKH();
    // const link_cskh = 'https://secure.livechatinc.com/licence/18795675/v2/open_chat.cgi';

    return (
        <>
            <div className="relative account">
                <div className="flex flex-col items-center justify-center mt-12">
                    <span className="font-semibold text-white fs-1">{nameUserBank}</span>
                    <div className="flex items-center justify-center mt-2">
                        {user?.data[0].roses_user == '1' && <img src={vip1icon} alt="" width={48} />}
                        {user?.data[0].roses_user == '2' && <img src={vip2icon} alt="" width={48} />}
                        {user?.data[0].roses_user == '3' && <img src={vip3icon} alt="" width={48} />}
                        {user?.data[0].roses_user == '4' && <img src={vip4icon} alt="" width={48} />}
                        {user?.data[0].roses_user == '5' && <img src={vip5icon} alt="" width={48} />}
                        {user?.data[0].roses_user == '6' && <img src={vip6icon} alt="" width={48} />}
                        {user?.data[0].roses_user == '7' && <img src={vip7icon} alt="" width={48} />}
                        {user?.data[0].roses_user == '8' && <img src={vip8icon} alt="" width={48} />}
                        <span className="font-semibold text-white fs-2" style={{ fontFamily: 'iconfont' }}>
                            {String(user?.data[0].name_level).toLocaleUpperCase()}
                        </span>
                    </div>
                </div>

                <div
                    className="main-container bg-[#f0f3f7] mt-4 overflow-x-hidden px-4 pb-[70px]"
                    style={{ borderRadius: '25px' }}
                >
                    <div className="flex items-center justify-between mt-4">
                        <div>
                            <Link
                                to="/my/invite"
                                className="flex items-center justify-center w-auto px-4 py-2 rounded-full"
                                style={{
                                    border: '1px solid #00b4ff',
                                }}
                                state={{ inviteCode: user?.data[0].id_user }}
                            >
                                <BsQrCodeScan size={24} className="text-[#00b4ff]" />
                                <span className="fs-2 ml-2 text-[#00b4ff] px-2">Mã mời</span>
                            </Link>
                        </div>
                        <Link
                            to={link_cskh}
                            target="_blank"
                            className="flex items-center justify-center px-4 py-2 ml-8 rounded-full"
                            style={{
                                border: '1px solid #00b4ff',
                            }}
                        >
                            <MdOutlineSupportAgent size={24} className="text-[#00b4ff]" />
                            <span className="fs-2 ml-2 text-[#00b4ff] px-2">CSKH</span>
                        </Link>
                    </div>
                    <div className="mt-24 tours-item flex flex-col items-center justify-start w-[100%] rounded-2xl mb-4 p-4 bg-white">
                        <div className="flex items-center justify-between w-[100%]">
                            <div className="flex font-medium fs-3">
                                Đơn hàng hôm nay{' '}
                                <span className="fs-3 font-medium text-[#e46937] ml-4">
                                    {isLoading ? (
                                        <Loading
                                            type="spinningBubbles"
                                            color="black"
                                            height={'20px'}
                                            width={'20px'}
                                            className="mb-3 ml-4"
                                        />
                                    ) : (
                                        `${user?.mission?.amountToday}/${user.data[0].amount}`
                                    )}
                                </span>
                            </div>
                            <div style={{ color: 'transparent' }}>1</div>
                        </div>

                        <ProgressBar
                            completed={`${user?.mission?.amountToday}`}
                            maxCompleted={`${user.data[0].amount}`}
                            height="5px"
                            className="mt-8 w-[100%]"
                            customLabel=""
                            isLabelVisible={false}
                            bgColor="#e46937"
                        ></ProgressBar>
                    </div>

                    <div className="mt-12 tours-item flex flex-col items-center justify-center w-[100%] rounded-2xl mb-4 p-4 bg-white">
                        <div
                            className="flex items-center justify-between pb-4 w-100"
                            style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}
                        >
                            <span className="font-medium fs-3">{t('content.my.thietLapCaNhan')}</span>
                            <div style={{ color: 'transparent' }}>1</div>
                        </div>

                        <div className="flex flex-col items-start justify-center w-[100%]">
                            <Link to="/order/index" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                    <FaFileAlt size={24} color="#ea943f" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">Hồ sơ đặt hàng</span>
                                    <span className="fs-4 text-[#6c757d]">Xem lịch sử đặt hàng</span>
                                </div>
                            </Link>
                            <Link to="/notification" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                    <IoMdNotifications size={24} color="#ea943f" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">Thông báo tin nhắn</span>
                                    <span className="fs-4 text-[#6c757d]">Xem tin nhắn hệ thống</span>
                                </div>
                            </Link>
                            <Link to="/my/address" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                    <PiAddressBook size={24} color="#ea943f" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">Địa chỉ nhận hàng</span>
                                    <span className="fs-4 text-[#6c757d]">Sửa thông tin địa chỉ</span>
                                </div>
                            </Link>
                            <Link to="/my/edit-password" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                    <MdPassword size={24} color="#ea943f" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.mkDN')}</span>
                                    <span className="fs-4 text-[#6c757d]">{t('content.my.thayDoiMKDN')}</span>
                                </div>
                            </Link>
                            <Link to="/my/edit-password-transaction" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                    <MdSecurity size={24} color="#ea943f" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.mkRT')}</span>
                                    <span className="fs-4 text-[#6c757d]">{t('content.my.thayDoiMKRT')}</span>
                                </div>
                            </Link>
                            <Link to="/my/banking" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                    <BsFillCreditCard2FrontFill size={24} color="#ea943f" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.tkNH')}</span>
                                    <span className="fs-4 text-[#6c757d]">{t('content.my.suaTTTK')}</span>
                                </div>
                            </Link>
                            <Link to="/term-conditions" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                    <MdOutlineGavel size={24} color="#ea943f" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.dieuKhoan')}</span>
                                    <span className="fs-4 text-[#6c757d]">{t('content.my.dkvadk')}</span>
                                </div>
                            </Link>
                            <Link to="/asked-questions" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                    <MdQuiz size={24} color="#ea943f" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.cauHoiTG')}</span>
                                    <span className="fs-4 text-[#6c757d]">{t('content.my.cauhoitg')}</span>
                                </div>
                            </Link>
                            <Link to="/about-us" className="flex items-center justify-center my-4">
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#d0cdf4] rounded-2xl">
                                    <MdDiversity3 size={24} color="#ea943f" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.veChungToi')}</span>
                                    <span className="fs-4 text-[#6c757d]">{t('content.my.vechungtoi')}</span>
                                </div>
                            </Link>
                            {/* 
                            <div className="flex items-center justify-between my-4 w-[100%] cursor-pointer">
                                <div className="flex items-center justify-center">
                                    <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#e46937] rounded-2xl">
                                        <AiOutlineGlobal size={24} color="white" />
                                    </div>
                                    <div className="flex flex-col ml-4">
                                        <span className="fs-3">{t('content.my.ngonNgu')}</span>
                                        <span className="fs-4 text-[#e46937]">{t('content.my.ngonngu')}</span>
                                    </div>
                                </div>

                                <div className="flex flex-col items-center justify-center w-[120px] bg-[#f3f3f3] text-xl rounded-2xl">
                                    {language === 'vi' && (
                                        <div
                                            className="flex items-center justify-between px-4 w-[100%] h-[50px]"
                                            onClick={() => changeLanguage('en')}
                                        >
                                            <span className="fs-3">Tiếng Việt</span>
                                            <img src={vietnam} alt="" width={24} />
                                        </div>
                                    )}

                                    {language === 'en' && (
                                        <div
                                            className="flex items-center justify-between px-4 w-[100%] h-[50px]"
                                            onClick={() => changeLanguage('vi')}
                                        >
                                            <span className="fs-3">English</span>
                                            <img src={england} alt="" width={24} />
                                        </div>
                                    )}
                                </div>
                            </div> */}

                            <div
                                className="flex items-center justify-center my-4"
                                onClick={() => {
                                    localStorage.removeItem('auth');
                                    localStorage.removeItem('code');
                                    window.location.href = '/account/login';
                                }}
                            >
                                <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#fae3e5] rounded-2xl">
                                    <MdPowerSettingsNew size={24} color="#dc3545" />
                                </div>
                                <div className="flex flex-col ml-4">
                                    <span className="fs-3">{t('content.my.dangX')}</span>
                                    <span className="fs-4 text-[#6c757d]">{t('content.my.thoat')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default My;
