import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

import { CiBarcode } from 'react-icons/ci';
import { IoChevronBackOutline } from 'react-icons/io5';
import { IoPhonePortraitOutline } from 'react-icons/io5';
import { LiaMoneyCheckAltSolid } from 'react-icons/lia';
import { Link } from 'react-router-dom';
import { LuUser2 } from 'react-icons/lu';
import { ModalSuccess } from '../../components/Modal/modalSuccess';
import { RiLockPasswordLine } from 'react-icons/ri';
import SETTINGS from '../../setting.json';
import classNames from 'classnames/bind';
import iconShowPass from '../../assets/images/icon-show-pass.png';
import styles from './Login.module.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormInput from '../../components/ui/Input/FormInput';
import ErrMsg from '../../components/ui/ErrMsg';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../components/ui/Button';
export function kiemTraSoDienThoai(soDienThoai) {
    const regex = /^\d{10}$/;
    return regex.test(soDienThoai);
}
const axios = require('axios').default;

const cx = classNames.bind(styles);

function Register(props) {
    const [isLoading, setIsLoading] = useState(false);

    const [isAgreeTerm, setIsAgreeTerm] = useState(true);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const searchParams = new URLSearchParams(location.search);
    const KEY_ACCEPT = {
        name: '',
        phone: '',
        password: '',
        repassword: '',
        withdrawPw: '',
        invite: searchParams.get('invitation_code') || '',
        isAgreeTerm: false,
    };
    document.title = props.title;

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: KEY_ACCEPT,
    });
    const onSubmit = async (values) => {
        setIsLoading(true);
        axios
            .post(`${SETTINGS.BASE_URL}/api/webapi/register`, {
                username: values['phone'],
                password: values['password'],
                invite: values['invite'],
                name: values['name'],
                withdrawPw: values['withdrawPw'],
                phone: values['phone'],
            })
            .then(function (response) {
                let status = response.data.status;
                if (status === 'ok') {
                    setTimeout(() => {
                        window.location.href = '/account/login';
                    }, 2000);
                    localStorage.setItem('auth', response.data.auth);
                    toast.success('Đăng ký thành công');
                    // setOpen(true);
                    return;
                }

                if (status === 'error' && response.data.message === 'Mã giới thiệu không tồn tại !')
                    return toast.error(t('content.register.maGTKTT'), { theme: 'light' });

                if (status === 'error' && response.data.message === 'Tài khoản này đã tồn tại trong hệ thống!')
                    return toast.error(t('content.register.taiKhoanDTT'), { theme: 'light' });
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <div className="relative">
            <div className="flex items-center justify-between header-account">
                <Link to="/account/login">
                    <IoChevronBackOutline size={30} color="white" />
                    {/* <img src={backIcon} alt="" width={20} /> */}
                </Link>
                <Link className="text-white fs-3" to="/account/login">
                    {t('content.register.daCoTaiKhoan')}
                </Link>
            </div>
            <div className={cx('side-content')}>
                <h3 className={cx('title')}> {t('content.register.taoTKM')}</h3>

                <label htmlFor="Label" className="pb-4 text-xl italic leading-10 text-center text-white">
                    {t('content.register.luuY')}
                </label>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid gap-y-1">
                        <div className="relative">
                            <div className={cx('form-group')}>
                                <LuUser2 className={cx('icon-left')} color="#e46937" size={20} />
                                <Controller
                                    control={control}
                                    name="name"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Không thể trống',
                                        },
                                        minLength: {
                                            value: 6,
                                            message: 'Tối thiểu 6 kí tự',
                                        },
                                        maxLength: {
                                            value: 12,
                                            message: 'Tối đa 12 kí tự',
                                        },
                                    }}
                                    render={({ field }) => {
                                        return (
                                            <FormInput
                                                id="name"
                                                placeholder={t('content.register.inputName')}
                                                mainClassName="px-0"
                                                rootClassName=""
                                                inputClassName="text-black rounded-[25px] bg-white text-[16px] px-[50px] py-[6.5px] leading-[3.2rem] border border-gray-400 w-[310px] max-w-[350px] h-auto"
                                                {...field}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <ErrMsg errMsg={errors?.name?.message} />
                        </div>
                        <div className="relative">
                            <div className={cx('form-group')}>
                                <IoPhonePortraitOutline className={cx('icon-left')} color="#e46937" size={20} />
                                <Controller
                                    control={control}
                                    name="phone"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Không thể trống',
                                        },
                                        pattern: {
                                            value: /^[0-9]{10}$/,
                                            message: 'Số điện thoại phải có 10 chữ số',
                                        },
                                    }}
                                    render={({ field }) => {
                                        return (
                                            <FormInput
                                                id="phone"
                                                placeholder={t('content.register.inputPhone')}
                                                mainClassName="px-0"
                                                rootClassName=""
                                                inputClassName="text-black rounded-[25px] bg-white text-[16px] px-[50px] py-[6.5px] leading-[3.2rem] border border-gray-400 w-[310px] max-w-[350px] h-auto"
                                                {...field}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <ErrMsg errMsg={errors?.phone?.message} />
                        </div>
                        <div className="relative">
                            <div className={cx('form-group')}>
                                <RiLockPasswordLine className={cx('icon-left')} color="#e46937" size={20} />
                                <Controller
                                    control={control}
                                    name="password"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Không thể trống',
                                        },
                                        minLength: {
                                            value: 6,
                                            message: 'Tối thiểu 6 kí tự',
                                        },
                                        maxLength: {
                                            value: 30,
                                            message: 'Tối đa 30 kí tự',
                                        },
                                        pattern: {
                                            value: /^(?=.*[a-zA-Z]).{6,30}$/,
                                            message: 'Phải có ít nhất 1 kí tự chữ',
                                        },
                                    }}
                                    render={({ field }) => {
                                        return (
                                            <FormInput
                                                type="password"
                                                id="password"
                                                placeholder={t('content.register.inputPw')}
                                                mainClassName="px-0"
                                                rootClassName=""
                                                inputClassName="text-black rounded-[25px] bg-white text-[16px] px-[50px] py-[6.5px] leading-[3.2rem] border border-gray-400 w-[310px] max-w-[350px] h-auto"
                                                {...field}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <ErrMsg errMsg={errors?.password?.message} />
                        </div>
                        <div className="relative">
                            <div className={cx('form-group')}>
                                <RiLockPasswordLine className={cx('icon-left')} color="#e46937" size={20} />
                                <Controller
                                    control={control}
                                    name="repassword"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Không thể trống',
                                        },
                                        validate: (value) => value === getValues('password') || 'Mật khẩu không khớp',
                                    }}
                                    render={({ field }) => {
                                        return (
                                            <FormInput
                                                type="password"
                                                id="repassword"
                                                placeholder={t('content.register.confirmPw')}
                                                mainClassName="px-0"
                                                rootClassName=""
                                                inputClassName="text-black rounded-[25px] bg-white text-[16px] px-[50px] py-[6.5px] leading-[3.2rem] border border-gray-400 w-[310px] max-w-[350px] h-auto"
                                                {...field}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <ErrMsg errMsg={errors?.repassword?.message} />
                        </div>
                        <div className="relative">
                            <div className={cx('form-group')}>
                                <LiaMoneyCheckAltSolid className={cx('icon-left')} color="#e46937" size={20} />
                                <Controller
                                    control={control}
                                    name="withdrawPw"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Không thể trống',
                                        },
                                        minLength: {
                                            value: 6,
                                            message: 'Phải có 6 kí tự',
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: 'Phải có 6 kí tự',
                                        },
                                    }}
                                    render={({ field }) => {
                                        return (
                                            <FormInput
                                                type="password"
                                                id="withdrawPw"
                                                placeholder={t('content.register.inputPw1')}
                                                mainClassName="px-0"
                                                rootClassName=""
                                                inputClassName="text-black rounded-[25px] bg-white text-[16px] px-[50px] py-[6.5px] leading-[3.2rem] border border-gray-400 w-[310px] max-w-[350px] h-auto"
                                                {...field}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <ErrMsg errMsg={errors?.withdrawPw?.message} />
                        </div>
                        <div className="relative">
                            <div className={cx('form-group')}>
                                <CiBarcode className={cx('icon-left')} color="#e46937" size={20} />
                                <Controller
                                    control={control}
                                    name="invite"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Không thể trống',
                                        },
                                    }}
                                    render={({ field }) => {
                                        return (
                                            <FormInput
                                                id="invite"
                                                placeholder={t('content.register.inputCode')}
                                                mainClassName="px-0"
                                                rootClassName=""
                                                inputClassName="text-black rounded-[25px] bg-white text-[16px] px-[50px] py-[6.5px] leading-[3.2rem] border border-gray-400 w-[310px] max-w-[350px] h-auto"
                                                {...field}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <ErrMsg errMsg={errors?.invite?.message} />
                        </div>
                    </div>
                    <div className="flex items-center justify-start w-[100%] w-[310px] mt-2">
                        <label className="switch">
                            <input type="checkbox" checked={isAgreeTerm} onClick={() => setIsAgreeTerm(!isAgreeTerm)} />
                            <span className="slider round"></span>
                        </label>
                        <span className="ml-4 text-white fs-4">{t('content.register.dongYDK')}</span>
                    </div>

                    <Link to="/term-conditions" className="text-white fs-3 font-semibold w-[100%] w-[310px] mt-2 block">
                        {t('content.register.DKvaDK')}
                    </Link>

                    <Button
                        title={t('content.login.dangKy')}
                        htmlType="submit"
                        rootClassName="bg-[#00b4ff] rounded-[20px] text-2xl mt-4"
                        disabled={!isAgreeTerm || isLoading}
                        isLoading={isLoading}
                    />
                </form>

                {/* <Link className="text-2xl font-bold text-white" to="https://t.me/cskhOkela6868" target="_blank">
                    {t('content.register.lienHeCSKH')}
                </Link> */}
            </div>

            <ModalSuccess open={open} toggle={() => setOpen(!open)} text={'Đăng ký thành công'} />
        </div>
    );
}

export default Register;
